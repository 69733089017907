.painting_link_box {
  padding-top: $vertical_rhythm;
}


.painting_link {
  display: block;
  margin-bottom: $vertical_rhythm;
  color: $grey--dark;
  &:hover {
    color: $title_hover_color;
  }
}

.painting_title {
    padding-top: $vertical_rhythm--small;
}

.painting_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 1rem;
  &.bigger {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  > div {
    padding: .5rem;
    //border-radius: $border_radius;
    //border: 1px $grey solid;
  }

  .painting_link {
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 100%;

  }
}

.painting_data  {
  padding-top: $vertical_rhythm--small/2;
  padding-bottom:0;
  line-height: 1.25;

  @include media("screen", ">minWidth") {
    display: table-row;
  }

  @include media("screen", ">minWidth") {

    .painting_data--content,
    .painting_data--name {
      display: table-cell!important;
      //padding-top: $vertical_rhythm--small;
      padding-right: $vertical_rhythm!important;
    }
  }

  .painting_data--content {
    font-weight: $font_weight--light;
    font-size: 16px;
    //text-align: center;
    display: block;
  }

  .painting_data--name {
    font-style: italic;
    font-size: 14px;
    font-weight: $font_weight--min;
    padding-right: $vertical_rhythm--small/3;
  }
}

.tag_page,
.serie {
  .painting_data--content {
    font-size: 14px;
  }
  .painting_dimensions {
    font-size: 13px;
    padding-top: $vertical_rhythm--small/4;
    .painting_data--content {
      font-weight: $font_weight--min!important;
    }
  }
}


.painting_serie a {
  font-weight: $font_weight--light!important;
}

.painting .painting_image {
  margin-bottom: $vertical_rhythm--small;
}
