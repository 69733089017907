.button_row {
  padding-top: $vertical_rhythm--small;
  padding-bottom: $vertical_rhythm--small;
  width: 100%;
  display: block;
  // this for the book_now button
  .product & {
    padding-top: $vertical_rhythm;
    padding-bottom: $vertical_rhythm;
    text-align: center;
    @include media( "screen", ">bp1Width") {
      text-align: left;
    }
  }

  .product_text & {
    padding-top: 0;
    padding-bottom: 0;
  }
}


.button {
  //a
  display: inline-block;
  width: auto;
  line-height: 32px;
  margin-top: $vertical_rhythm--small/2;
  margin-right: $vertical_rhythm--small/2;
  margin-bottom: $vertical_rhythm--small/2;
  border: 1px solid $black_stroke;
  border-radius: 4px;
  color: $black;
  padding: 0 10px;
  opacity: 0.9;
  -webkit-box-shadow: $card_shadow;
  box-shadow: $card_shadow;

  &:focus,
  &:hover {
    cursor: pointer;
    opacity: 1;
    background-color: $button_focus_bg;
  }

  &:active {
    cursor: pointer;
    background-color: $button_focus_bg;
    -webkit-transform: translateY(0.25em);
    transform: translateY(0.25em);
  }
  &.hide {
    display: none;
  }

  .book_now & {
    background-color: $button_book;
    color: white;
    font-weight: $font_weight--reg;
  }
}
