//rok&scroll
$font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;

$brand_color: white;
$complement_brand_color: lighten(complement($brand_color), 30);
$invert_brand_color: lighten(invert($brand_color), 30);

$black: hsl(0, 0%, 0%);
$grey: hsl(0, 0%,80%);
$grey--light: hsl(0,0%,90%);
$grey--dark: hsl(0,0%,65%);
$grey--darker: hsl(0,0,50%);
$grey--darkest: hsl(0,0,35%);
$grey--black: hsl(0,0,20%);

$link_color: $grey--darkest;
$title_hover_color: #f400a0;
$bg_color: hsl(0, 0%, 100%);
$underline_link_color : $grey--light;
$product_category_1: hsla(205, 100%, 75%, .75);
$product_category_2: hsla(205, 100%, 85%, .75);
$product_category_3: hsla(205, 100%, 94%, .75);

$vertical_rhythm: 2rem;
$vertical_rhythm--small: 1rem;

$border_radius: 4px;

$base_font_size--tiny: 15px;
$base_font-size--min: 16px;
$base_font_size--bp1: 17px;
$base_font_size--max: 18px;
$base_line_height: 1.4446;

$font_weight--min: 300;
$font_weight--light: 400;
$font_weight--reg: 500;
$font_weight--strong: 600;
$font_weight--heavy: 700;
$font_weight--max: 800;

//all svg icons are square:
$icon_size: 30px;

// gradients
$gradient_color_11:#eee;
$gradient_color_12:#fff;
$gradient_1: linear-gradient($gradient_color_11,$gradient_color_12);


//shadows
$header_shadow: 0px 2px 10px rgba($black,0.16);
$card_shadow: 0px 0px 2px 0px rgba($black,0.12), 0px 2px 2px 0px rgba($black,0.24);
$card_shadow--hover: 0px 0px 8px 0px rgba($black,0.12), 0px 8px 8px 0px rgba($black,0.24);

$transition_shadow: box-shadow 0.2s ease;

// buttons
$black_stroke: hsl(0,0%,60%);
$button_focus_bg: hsla(0, 0%, 91%, 1);
$button_book: hsl(205, 100%, 51%);

//// Predefined Break-points
$mediaMinWidth: 600px;
$mediaBp1Width: 840px;
$mediaMaxWidth: 1260px;

// fixed headings
$heading1: 1.8rem;
$heading2: calc($heading1/8*6);
$heading3: calc($heading1/8*5);

// responsive headings
$header_responsive: 8vw;
$header_min: 30px;
$header_max: 52px;

$heading1_responsive: 4vw;
$heading1_min: 26px;
$heading1_max: 46px;

$heading2_responsive: 3.5vw;
$heading2_min: 24px;
$heading2_max: 42px;

$heading3_responsive: 3vw;
$heading3_min: 22px;
$heading3_max: 36px;

$heading4_responsive: 2.5vw;
$heading4_min: 20px;
$heading4_max: 33px;

$heading5_responsive: 2.25vw;
$heading5_min: 18px;
$heading5_max: 30px;

$heading6_responsive: 2vw;
$heading6_min: 16px;
$heading6_max: 26px;


@import
"1normalize",
"2cmq",
"3base",
"4responsive_font",
"5headings",
"6menu",
"7header",
"8footer",
"9svg",
"10buttons",
"11links",
"12titles",
"13hero",
"14products",
"15cards",
"16social_sharing",
"17responsive_tables",
"18paintings",
"19currently-available",
"20tag_cloud"
;
