.hero {
  position: relative;
  height: calc(100vh - 40px);
  overflow: hidden;
  z-index: 1;
  @include media( "screen", ">maxWidth") {
    height: auto;
  }
}

.hero_image {
  width: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 2;
  //fix
  min-width: 100%;
  height: 100%;
  @media only screen and (orientation: landscape) {
    height: auto;
  }
  @include media( "screen", ">bp1Width") {
    min-width: 100%;
    height: auto;
    min-height: calc(100vh - 40px);
  }
  @include media( "screen", ">maxWidth") {
    position: static;
    transform: none;
    width: 100%;
  }

  &.align_top {
    bottom: auto;
    top:  0;
    transform: translate(-50%,0);
    @include media( "screen", ">maxWidth") {
      transform: none;
    }
  }

  &.align_bottom {
    bottom: 0;
    top: auto;
    transform: translate(-50%,0);
    @include media( "screen", ">maxWidth") {
      transform: none;
    }
  }
}

.hero_caption {
  color: $brand_color;
  position: absolute;
  top: 30px;
  width: 100%;
  left: 50%;
  transform: translate(-50%,0);
  z-index: 100;
  padding-left: 5%;
  padding-right: 5%;
  @include media( "screen", ">minWidth") {
    padding-left: 10%;
    padding-right: 10%;
  }
  h1 {
    //font-size: 2.8rem;
    text-transform: uppercase;
    font-weight: $font_weight--heavy;
    line-height: 1;
    text-align: center;
  }

}
