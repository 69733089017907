// just for content link, or for footer nor header nor menus

a {
  font-weight: $font_weight--min;
  text-decoration: none;
  color: $link_color;
  border-bottom: 1px solid transparent;

  .footer &,
  .tagcloud_block & {
    color: $grey--darkest;
  }

  &:hover,
  &:focus {
    color: darken($grey--darkest, 30%);
  }

  &.active {
    border-bottom: $grey--dark solid 1px;

    .header & {
      border-bottom: 0 none;
      @include media( "screen", ">minWidth") {
        border-bottom: $grey--dark solid 1px;
      }
    }
    span {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}


[role="main"] {
  a:not(.card):not(.button):not(.painting_link) {
    color: $link-color;
    text-decoration: none;
    border-bottom: 3px solid $underline_link_color;
    box-shadow: inset 0 -5px 0 $underline_link_color;
    /* box shadow adds additional thickness*/
    -webkit-transition: background 0.15s cubic-bezier(.33,.66,.66,1);
    transition: background 0.15s cubic-bezier(.33,.66,.66,1);

    &:focus,
    &:hover {
      background: $underline_link_color;
    }

    strong {
      color: inherit;
    }

    // `:visited` state is usefull! if they just could work... cos they dont here
    //&:visited {
      // border-bottom: 3px solid $grey--light;
      // box-shadow: inset 0 -5px 0 $grey--light;
      // &:focus,
      // &:hover {
      //   background: $grey--light;
      // }
    //}
  }
}
